import SpartaLogo from '@/_global/_components/Icon/SpartaIcon';
import { ROUTES } from '@/_global/_constants/routes';
import { DeviceSpecific } from '@/_global/_components/_higher_order/DeviceSpecific/DeviceSpecific';
import BackButton from '../../_components/Gnb/_components/IconElementButton/GnbLeftElementBackButton';
import CloseButton from '../../_components/Gnb/_components/IconElementButton/GnbRightElementCloseButton';
import MyPageButton from '../../_components/Gnb/_components/IconElementButton/GnbRightElementMypageButton';
import ClassroomTitle from '../../_components/Gnb/_components/TitleElement/ClassroomTitle';
import TitleTemplate from '../../_components/Gnb/_components/TitleElement/TitleTemplate';
import { GnbConfig } from '@/(_pages)/(withGnb)/_domain/_type/gnb.type';

export const GNB_CONFIG: Record<string, GnbConfig | null> = {
  [ROUTES.HOME]: {
    leftElement: <SpartaLogo />,
    rightElement: <MyPageButton />,
  },
  [ROUTES.CLASSROOM]: {
    title: <ClassroomTitle />,
    leftElement: <SpartaLogo />,
    rightElement: <MyPageButton />,
  },
  [ROUTES.PAYMENT]: {
    title: <TitleTemplate title="결제하기" />,
    leftElement: (
      <DeviceSpecific
        mobileElement={<BackButton />}
        desktopElement={<SpartaLogo isMainLogo={false} />}
      />
    ),
  },
  [ROUTES.PAYMENT_FAILED]: null,
  [ROUTES.PAYMENT_DONE]: {
    title: <TitleTemplate title="결제하기" />,
    leftElement: <DeviceSpecific desktopElement={<SpartaLogo isMainLogo={false} />} />,
    rightElement: <DeviceSpecific mobileElement={<CloseButton />} />,
  },
};
