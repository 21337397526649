'use client';
import { CreateContext } from '../../utils/createContext';
import { type Breakpoint } from '../../types/breakpoint';

/**
 * @description Breakpoint를 주입받기 위한 Context입니다.
 * mobile, tablet, wide 외 다른 키를 추가할 수 있습니다.
 */
export type BreakpointContext = Partial<Record<Breakpoint, number>>;

/**
 * @description ViewportContext를 생성하는 Provider와 useContext를 반환합니다.
 * 디자인 시스템에서 Breakpoint를 주입받기 위해 사용합니다.
 */
const [BreakpointProvider, useBreakpointContext] = CreateContext<BreakpointContext>(
  'BreakpointContextProvider',
  {
    narrow: 0,
    // tablet: 768,
    wide: 1024,
  },
);

export { BreakpointProvider, useBreakpointContext };
