import { getCookie } from 'cookies-next';
import { UserEssentialInfoKey, UserInfo, UserInfoKey } from '@/login/_domain/_types/login.type';

export const getUserId = () => getCookie('user_id') as string;

export const getUserInfoObject = () => {
  const userInfo = getCookie('userinfo') as string;
  const userInfoObject: UserInfo = {
    name: '',
    email: '',
    phone: '',
  };
  if (userInfo) {
    userInfo.split('&').forEach((item) => {
      const [key, value] = item.split('=');
      userInfoObject[key as keyof UserInfo] = value;
    });
  }
  return userInfoObject;
};

export function getUserInfo<T extends UserEssentialInfoKey>(key: T): string;
export function getUserInfo<T extends UserInfoKey>(key: T): UserInfo[T];
export function getUserInfo(key: UserInfoKey) {
  const userinfo = getUserInfoObject();
  return userinfo[key];
}
