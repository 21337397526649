export const PointIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon_point_fill_24">
        <circle id="Ellipse 532" cx="8.75" cy="8.5" r="6" stroke="#3A3E41" strokeWidth="1.5" />
        <g id="Group 2598">
          <path
            id="Vector 165"
            d="M7.54956 5.96729V11.3006"
            stroke="#3A3E41"
            strokeWidth="1.2"
            strokeLinecap="round"
          />
          <path
            id="Vector 170"
            d="M9.21623 5.96729H7.54956V9.30062H9.21623C10.3273 9.30062 10.8829 8.63395 10.8829 7.63395C10.8829 6.63395 10.3273 5.96729 9.21623 5.96729Z"
            stroke="#3A3E41"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export const CouponIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <mask id="path-1-inside-1_283_19679" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08325 4.40909C2.08325 3.90701 2.49027 3.5 2.99234 3.5H14.5075C15.0096 3.5 15.4166 3.90701 15.4166 4.40909V7.16667C14.6802 7.16667 14.0833 7.76362 14.0833 8.5C14.0833 9.23638 14.6802 9.83333 15.4166 9.83333V12.5909C15.4166 13.093 15.0096 13.5 14.5075 13.5H2.99234C2.49026 13.5 2.08325 13.093 2.08325 12.5909V9.83333C2.81963 9.83333 3.41659 9.23638 3.41659 8.5C3.41659 7.76362 2.81963 7.16667 2.08325 7.16667V4.40909Z"
        />
      </mask>
      <path
        d="M15.4166 7.16667V8.66667H16.9166V7.16667H15.4166ZM15.4166 9.83333H16.9166V8.33333H15.4166V9.83333ZM2.08325 9.83333V8.33333H0.583252V9.83333H2.08325ZM2.08325 7.16667H0.583252V8.66667H2.08325V7.16667ZM2.99234 2C1.66184 2 0.583252 3.07859 0.583252 4.40909H3.58325C3.58325 4.73544 3.31869 5 2.99234 5V2ZM14.5075 2H2.99234V5H14.5075V2ZM16.9166 4.40909C16.9166 3.07859 15.838 2 14.5075 2V5C14.1811 5 13.9166 4.73544 13.9166 4.40909H16.9166ZM16.9166 7.16667V4.40909H13.9166V7.16667H16.9166ZM15.5833 8.5C15.5833 8.59205 15.5086 8.66667 15.4166 8.66667V5.66667C13.8518 5.66667 12.5833 6.93519 12.5833 8.5H15.5833ZM15.4166 8.33333C15.5086 8.33333 15.5833 8.40795 15.5833 8.5H12.5833C12.5833 10.0648 13.8518 11.3333 15.4166 11.3333V8.33333ZM16.9166 12.5909V9.83333H13.9166V12.5909H16.9166ZM14.5075 15C15.838 15 16.9166 13.9214 16.9166 12.5909H13.9166C13.9166 12.2646 14.1811 12 14.5075 12V15ZM2.99234 15H14.5075V12H2.99234V15ZM0.583252 12.5909C0.583252 13.9214 1.66184 15 2.99234 15V12C3.31869 12 3.58325 12.2646 3.58325 12.5909H0.583252ZM0.583252 9.83333V12.5909H3.58325V9.83333H0.583252ZM1.91659 8.5C1.91659 8.40795 1.99121 8.33333 2.08325 8.33333V11.3333C3.64806 11.3333 4.91659 10.0648 4.91659 8.5H1.91659ZM2.08325 8.66667C1.9912 8.66667 1.91659 8.59205 1.91659 8.5H4.91659C4.91659 6.93519 3.64806 5.66667 2.08325 5.66667V8.66667ZM0.583252 4.40909V7.16667H3.58325V4.40909H0.583252Z"
        fill="#3A3E41"
        mask="url(#path-1-inside-1_283_19679)"
      />
      <path
        d="M10.0833 7.1665L7.41659 9.83317"
        stroke="#3A3E41"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="7.41667" cy="7.16667" r="0.666667" fill="#3A3E41" />
      <circle cx="10.0834" cy="9.83317" r="0.666667" fill="#3A3E41" />
    </svg>
  );
};
