'use client';
import { colorProperties, fontProperties } from '@/_global/_styles/sprinkles/sprinkles.css';
import { useContext } from 'react';
import { Responsive } from '../../types/responsive';
import { SprinklesContext } from '../Providers/SprinklesContext';

// type Conditions = (typeof fontProperties)['conditions'];
// type ConditionNames = Conditions['conditionNames'][number];

type FontValue = keyof typeof fontProperties.styles.font.values;
export type ColorValue = keyof typeof colorProperties.styles.color.values;

type TextProps = {
  children: React.ReactNode;
  as?: React.ElementType;
  // font: FontValue | Partial<Record<ConditionNames, FontValue>>;
  font: FontValue | Responsive<FontValue>;
  color: ColorValue;
  gradiant?: {
    color: ColorValue;
    deg: number;
  };
  style?: React.CSSProperties;
};

export const Text = ({ children, as, font, color, gradiant, style }: TextProps) => {
  const sprinkles = useContext(SprinklesContext);
  const Component = as || 'span'; //여기 p로 하면 hydration error 발생

  // const isFontValue = (value: unknown): value is FontValue =>
  //   typeof value === 'string' && value in fontProperties.styles.font.values;

  // const fontProps = isFontValue(font) ? { wide: font, narrow: font } : font;

  const fontClass = sprinkles({ font });

  let gradientColor = {};
  if (gradiant) {
    gradientColor = {
      background: `linear-gradient(${gradiant.deg}deg, ${color} 0%, ${gradiant.color} 100%`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    };
  }

  return (
    <Component
      className={fontClass}
      style={{ color, ...gradientColor, whiteSpace: 'pre-line', ...style }}
    >
      {children}
    </Component>
  );
};
