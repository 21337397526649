'use client';
import { AppProgressBar } from 'next-nprogress-bar';

const ProgressBar = () => {
  return (
    <AppProgressBar height="3px" color="#ff6c7a" options={{ showSpinner: false }} shallowRouting />
  );
};

export default ProgressBar;
