import React from 'react';
import * as S from './deviceSpecific.css';

type LearningEnvironmentProps = {
  isLMS?: true;
  narrowElement?: React.ReactNode;
  wideElement?: React.ReactNode;
};

type RegularEnvironmentProps = {
  isLMS?: false;
  mobileElement?: React.ReactNode;
  desktopElement?: React.ReactNode;
};

type DeviceSpecificElementProps = LearningEnvironmentProps | RegularEnvironmentProps;

export const DeviceSpecific = ({ isLMS = false, ...props }: DeviceSpecificElementProps) => {
  if (isLMS) {
    const { narrowElement, wideElement } = props as LearningEnvironmentProps;
    return (
      <div>
        {/* 500 미만 */}
        <div className={S.deviceSpecificShowStyles({ displayOnly: 'narrow' })}>{narrowElement}</div>
        {/* 500 이상 */}
        <div className={S.deviceSpecificShowStyles({ displayOnly: 'wide' })}>{wideElement}</div>
      </div>
    );
  } else {
    const { mobileElement, desktopElement } = props as RegularEnvironmentProps;
    return (
      <div>
        {/* 모바일 전용 */}
        <div className={S.deviceSpecificShowStyles({ displayOnly: 'mobile' })}>{mobileElement}</div>
        {/* 데스크탑 전용 */}
        <div className={S.deviceSpecificShowStyles({ displayOnly: 'tabletAndDesktop' })}>
          {desktopElement}
        </div>
      </div>
    );
  }
};
