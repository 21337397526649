export const IN_HOUSE_URL: Record<string, string> = {
  메인: process.env.NEXT_PUBLIC_SCC_URL!,
  수업탐색: `${process.env.NEXT_PUBLIC_SCC_URL}/catalog`,
  상품탐색: `${process.env.NEXT_PUBLIC_SCC_URL}/catalog/scc`,
  국비신청내역: `${process.env.NEXT_PUBLIC_SCC_URL}/kdc/registration_status`,
  자유게시판: `${process.env.NEXT_PUBLIC_SCC_URL}/community/freeboard`,
  학습질문: `${process.env.NEXT_PUBLIC_SCC_URL}/community/fastqna/all`,
  블로그: `${process.env.NEXT_PUBLIC_SCC_URL}/blog`,
  이벤트: `${process.env.NEXT_PUBLIC_SCC_URL}/event`,
  구_내강의실: `${process.env.NEXT_PUBLIC_ONLINE_URL}/classroom`,
  내강의실: `/classroom`,
  수강권: `${process.env.NEXT_PUBLIC_ONLINE_URL}/mypage/myvoucher`,
  수강증: `${process.env.NEXT_PUBLIC_ONLINE_URL}/mypage/mycertificate`,
  계정: `${process.env.NEXT_PUBLIC_ONLINE_URL}/mypage/mybasicinfo`,
  쿠폰: `${process.env.NEXT_PUBLIC_ONLINE_URL}/mypage/mycoupon`,
  포인트: `${process.env.NEXT_PUBLIC_ONLINE_URL}/mypage/mypoint`,
  숙제피드백: `${process.env.NEXT_PUBLIC_ONLINE_URL}/homework/feedback`,
  로그아웃: `${process.env.NEXT_PUBLIC_ONLINE_URL}/logout?next=${process.env.NEXT_PUBLIC_SCC_URL}`,
  결제: `/payment`,
  결제성공: `/payment/done`,
  결제실패: `/payment/failed`,
};
