'use client';
import { Flex } from '@/_global/_styles/design-system/components';
import * as S from './gnb.css';
import { useGnbConfig } from '@/(_pages)/(withGnb)/_domain/_business/_hook/useGnbConfig';
import ProgressBar from '@/(_pages)/(withGnb)/_components/Gnb/_components/ProgressBar/ProgressBar';

type Props = {
  serverPath?: string;
};

export const Gnb = ({ serverPath }: Props) => {
  const gnbConfig = useGnbConfig(serverPath);

  return (
    gnbConfig && (
      <header className={S.GnbWrapper}>
        <ProgressBar />
        <section className={S.GnbLayout}>
          {/* left */}
          <Flex>{gnbConfig.leftElement}</Flex>

          {/* title */}
          <div className={S.TitleWrapper}>{gnbConfig.title}</div>

          {/* right */}
          <Flex>{gnbConfig.rightElement}</Flex>
        </section>
      </header>
    )
  );
};
