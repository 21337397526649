'use client';

import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { NavigationClose, NavigationMyPage } from '@/_global/_styles/design-system/foundation/icon';
import { useState } from 'react';
import MyPageDashboard from '../MyPageDashboard/MyPageDashboard';
import * as S from './style.css';
import { DeviceSpecific } from '@/_global/_components/_higher_order/DeviceSpecific/DeviceSpecific';

export default function MyPageButton() {
  const [isMyPageOpen, setIsMyPageOpen] = useState(false);

  const handleClickMyPage = () => {
    setIsMyPageOpen((prev) => !prev);
  };

  return (
    <div className={S.ProfileIconWrapper}>
      <button className={S.ProfileIcon} onClick={handleClickMyPage}>
        <DeviceSpecific
          mobileElement={
            isMyPageOpen ? (
              <NavigationClose size={20} color={neutralDay.gray90} />
            ) : (
              <NavigationMyPage size={20} style="line" color={neutralDay.gray90} />
            )
          }
          desktopElement={<NavigationMyPage size={24} style="line" color={neutralDay.gray90} />}
        />
      </button>
      <MyPageDashboard show={isMyPageOpen} setShow={setIsMyPageOpen} />
    </div>
  );
}
