import { baseStyle } from './divider.css';

export type Orientations = 'horizontal' | 'vertical';

export interface DividerProps {
  size: number;
  color: string;
  orientation: Orientations;
  isFlexItem: boolean;
}

export const Divider = ({ size, color, orientation, isFlexItem }: DividerProps) => {
  const orientationStyle =
    orientation === 'horizontal'
      ? {
          width: '100%',
          borderTop: `${size}px solid ${color}`,
        }
      : {
          height: isFlexItem ? 'auto' : '100%',
          borderLeft: `${size}px solid ${color}`,
          alignSelf: isFlexItem ? 'stretch' : 'auto',
        };

  return <hr className={baseStyle} style={orientationStyle} />;
};
