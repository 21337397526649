import { Flex } from '@/_global/_styles/design-system/components';
import { Text } from '@/_global/_styles/design-system/components/Text/Text';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';

export default function TitleTemplate({ title }: { title: string }) {
  return (
    <Flex justify={'center'} fullWidth>
      <Text as="p" font="mTitle2" color={neutralDay.gray100}>
        {title}
      </Text>
    </Flex>
  );
}
