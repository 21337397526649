// @ts-check

import axios, { AxiosRequestConfig } from 'axios';
import { getCookie } from 'cookies-next';

export const apiUrl = process.env.NEXT_PUBLIC_ONLINE_API_V3_URL;
export const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': `application/json`,
  },
});

interface AxiosOptions {
  params?: Record<string, unknown>;
  data?: unknown;
  headers?: Record<string, string>;
  timeout?: number;
  withAuth?: boolean;
}

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

const request = async <T>(
  method: HttpMethod,
  endpoint: string,
  options: AxiosOptions = {},
): Promise<T> => {
  const { params, data, headers = {}, timeout, withAuth = true } = options;
  if (withAuth) {
    Object.assign(headers, {
      Authorization: `Bearer ${getCookie('token')}`,
    });
  }

  const config: AxiosRequestConfig = {
    method,
    url: endpoint,
    params,
    headers,
    timeout,
    data,
  };

  const response = await axiosInstance.request(config);
  return response.data;
};

// baseURL: ONLINE_V3_API
const Axios = <T>(method: HttpMethod, endpoint: string, options?: AxiosOptions): Promise<T> =>
  request<T>(method, endpoint, options);

export default Axios;
