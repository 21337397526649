import {
  ClassroomEnrollment,
  ConstructorType,
  EnrollmentTicketDetail,
  EnrollStatusIncludingExpired,
  GetAllEnrollmentsResV1,
} from '@/(_pages)/(withGnb)/classroom/_domain/_types/classroom.type';
import { EnrollmentDashboard } from '@/_global/_data/_models';
import { UseQueryResult } from '@tanstack/react-query';
import { applyEnvUrl } from '@/_global/_utils/applyEnvUrl';

export const convertEnrollmentCourseDtoIntoProductDto = (
  data: GetAllEnrollmentsResV1,
): Array<ClassroomEnrollment> =>
  data.context.map((enrollment) => ({
    id: enrollment.enrolled_id,
    courseStartDate: enrollment.course_start_date,
    courseEndDate: enrollment.course_end_date,
    keepStartDate: enrollment.keeping_start_date,
    keepEndDate: enrollment.keeping_end_date,
    courseId: enrollment.course_id,
    isQualified: enrollment.is_qualified,
    progressRate: enrollment.progress_rate,
    qualificationCriteria: enrollment.qualification_criteria,
    productName: enrollment.product_name,
    courseName: enrollment.course_name,
    productThumbnailUrl: `https://video.spartacodingclub.kr/classroom/thumb/${enrollment.product_keyword}_classroom_thumbnail.png`,
    passedLessonCount: enrollment.total_passed_lesson_count,
    totalLessonCount: enrollment.total_lesson_count,
    constructorType: enrollment.constructor_type,
    createdAt: enrollment.constructed_at,
    navigateTo: `${process.env.NEXT_PUBLIC_ONLINE_URL}${enrollment.navigate_link}/roadmap`,

    // 이하 v1 에는 존재하지 않는 필드
    addOnServices: [],
    isCompleted: enrollment.is_completed,
    completionCriteria: enrollment.completion_criteria,
  }));

export const convertEnrollmentDtoIntoStructure = (
  resultsInDto: [
    UseQueryResult<ClassroomEnrollment[], Error>,
    UseQueryResult<EnrollmentDashboard[], Error>,
  ],
): Array<EnrollmentTicketDetail> => {
  const CONSTRUCTOR_TYPE_MAP: Record<string, ConstructorType> = {
    PAID: '구매',
    PRESENTED: '지급',
  } as const;

  const getPeriods = (
    enrollment: ClassroomEnrollment,
  ): EnrollmentTicketDetail['enrollment']['periods'] => {
    // 서버에서 받아오는 데이터에는 시간이 포함되어 있으나, 검증 시에는 *날짜만* 비교함
    const toMidnight = (date: Date) => new Date(new Date(date).setHours(0, 0, 0, 0));

    const getPeriod = (startDate: string, endDate: string) => {
      if (!startDate || !endDate || startDate === endDate) return undefined;
      return {
        start: new Date(startDate),
        end: new Date(endDate),
      };
    };

    const getIsDateInPeriod = (date: Date, range?: { start: Date; end: Date }) => {
      if (!range) return false;
      const targetDate = toMidnight(date);
      return targetDate >= toMidnight(range.start) && targetDate < toMidnight(range.end);
    };

    const getEnrollmentStatus = (
      course?: { start: Date; end: Date },
      keep?: { start: Date; end: Date },
    ): EnrollStatusIncludingExpired => {
      const now = new Date();

      const statusChecks = [
        { condition: () => course && now < toMidnight(course.start), status: '수강전' },
        { condition: () => getIsDateInPeriod(now, course), status: '수강중' },
        { condition: () => getIsDateInPeriod(now, keep), status: '소장중' },
      ] as const;

      return statusChecks.find((check) => check.condition())?.status ?? '종료됨';
    };

    const course = getPeriod(enrollment.courseStartDate, enrollment.courseEndDate);
    const keep = getPeriod(enrollment.keepStartDate, enrollment.keepEndDate);
    const status = getEnrollmentStatus(course, keep);

    return { course, keep, status };
  };

  const convertEnrollment = (enrollment: ClassroomEnrollment): EnrollmentTicketDetail => ({
    constructor: {
      type: CONSTRUCTOR_TYPE_MAP[enrollment.constructorType] as ConstructorType,
      date: new Date(enrollment.createdAt),
    },
    product: {
      thumbnail: enrollment.productThumbnailUrl,
      name: enrollment.productName,
      courseName: enrollment.courseName,
      totalLessonCount: enrollment.totalLessonCount,
      qualificationCriteria: enrollment.qualificationCriteria,
      completionCriteria: enrollment.completionCriteria,
      courseId: enrollment.courseId,
    },
    enrollment: {
      id: enrollment.id,
      records: {
        passedLessonCount: enrollment.passedLessonCount,
        isQualified: enrollment.isQualified,
        isCompleted: enrollment.isCompleted,
        navigateTo: applyEnvUrl(enrollment.navigateTo),
      },
      periods: getPeriods(enrollment),
      addOnServices: enrollment.addOnServices,
    },
  });

  return resultsInDto.flatMap(
    (result) =>
      result.data
        ?.map(convertEnrollment)
        ?.filter((ticket) => ticket.enrollment.periods.status !== '종료됨') ?? [],
  );
};
