import Axios from '@/_global/_utils/axiosInstance.util';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { UserMembership, UserMyPageInfo } from '../../_types/user.type';

export const userKeys = {
  all: ['user'],
  membership: () => [...userKeys.all, 'membership'],
  couponCount: () => [...userKeys.all, 'couponCount'],
};

export const userQuery = {
  getUserMyPageInfo: () =>
    queryOptions<UserMyPageInfo>({
      queryKey: userKeys.all,
      queryFn: () => Axios('get', '/user'),
    }),
  getUserCouponCount: () =>
    queryOptions<number>({
      queryKey: userKeys.couponCount(),
      queryFn: () => Axios('get', '/user-info/available-coupons/count'),
    }),
  getUserMembership: () =>
    queryOptions<UserMembership>({
      queryKey: userKeys.membership(),
      queryFn: () => Axios('get', '/user/membership'),
    }),
};

export const useGetUserMyPageInfo = () => useQuery<UserMyPageInfo>(userQuery.getUserMyPageInfo());
export const useGetUserCouponCount = () => useQuery<number>(userQuery.getUserCouponCount());
export const useGetUserMembership = () => useQuery<UserMembership>(userQuery.getUserMembership());
