'use client';
// utils/createContext.tsx
import { createContext, ReactNode, useContext, useMemo } from 'react';

function CreateContext<ContextValueType extends object | null>(
  rootComponentName: string,
  defaultContext?: ContextValueType,
) {
  const Context = createContext<ContextValueType | undefined>(defaultContext);

  function Provider(props: ContextValueType & { children: ReactNode }) {
    const { children, ...context } = props;

    const value = useMemo(
      () => context,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(context),
    ) as ContextValueType;
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  function useCustomContext(consumerName: string) {
    const context = useContext(Context);
    if (context) return context;
    if (defaultContext !== undefined) return defaultContext;
    throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
  }

  Provider.displayName = rootComponentName + 'Provider';
  return [Provider, useCustomContext] as const;
}

export { CreateContext };
