import { ROUTES } from '@/_global/_constants/routes';
import { GNB_CONFIG } from '@/(_pages)/(withGnb)/_domain/_data/gnb.model';

export const isPaymentPath = (pathname: string) => pathname?.startsWith(ROUTES.PAYMENT);
export const isPaymentFailedPath = (pathname: string) => pathname?.includes(ROUTES.PAYMENT_FAILED);

export const getGnbConfig = (path?: string) => {
  if (!path) return GNB_CONFIG[ROUTES.HOME];

  if (GNB_CONFIG[path]) {
    return GNB_CONFIG[path];
  }
  if (isPaymentFailedPath(path)) {
    return GNB_CONFIG[ROUTES.PAYMENT_FAILED];
  }
  if (isPaymentPath(path)) {
    return GNB_CONFIG[ROUTES.PAYMENT];
  }
  return GNB_CONFIG[ROUTES.HOME];
};

export const getFooterConfig = (path: string) =>
  !(isPaymentPath(path) || path?.includes('signup_kko'));
