'use client';
import { CreateContext } from '../../utils/createContext';
import { type CSSProperties, type PropsWithChildren } from 'react';

interface ThemeColor {
  primary: string;
  secondary: string;
  tertiary: string;
}

export type ThemeMode = 'light' | 'dark';

export type ThemeContext = {
  colors: Record<ThemeMode, ThemeColor>;
  mode: ThemeMode;
};

export interface ThemeProviderProps {
  colors?: Record<ThemeMode, ThemeColor>;
  mode?: ThemeMode;
}

const [
  /**
   * @description
   * @example
   */
  ThemeContextProvider,
  useThemeContext,
] = CreateContext<ThemeContext>('ThemeContextProvider', {
  colors: {
    light: { primary: '#E8344E', secondary: '#141617', tertiary: '#EEF3F6' },
    dark: { primary: '#DE1912', secondary: '#EEF3F6', tertiary: '#3A3E41' },
  },
  mode: 'light',
});

/**
 * @description ThemeContext를 생성하는 Provider와 useContext를 반환합니다.
 * 디자인 시스템에서 Theme을 주입받기 위해 사용합니다.
 */
function ThemeProvider({
  children,
  colors: colorsFromProps,
  mode: modeFromProps,
}: PropsWithChildren<ThemeProviderProps>) {
  const { colors: colorsFromContext, mode: modeFromContext } = useThemeContext('ThemeProvider');

  const mode = modeFromProps ?? modeFromContext;
  const colors = colorsFromProps ?? colorsFromContext;

  const style = {
    '--primary-color': colors[mode].primary,
    '--secondary-color': colors[mode].secondary,
    '--tertiary-color': colors[mode].tertiary,
  } as unknown as CSSProperties;

  return (
    <ThemeContextProvider
      colors={{
        ...colors,
        ...colorsFromProps,
      }}
      mode={mode}
    >
      <div
        style={{
          ...style,
          display: 'contents',
        }}
      >
        {children}
      </div>
    </ThemeContextProvider>
  );
}

export { ThemeProvider, useThemeContext };
