'use client';

import { useNavigator } from '@/_global/_business/_hooks/useNavigator';
import { IN_HOUSE_URL } from '@/_global/_constants/urls';
import { Button } from '@/_global/_styles/design-system/components/Button/Button';
import { neutralDay } from '@/_global/_styles/design-system/foundation/color';
import { NavigationClose } from '@/_global/_styles/design-system/foundation/icon';
import React from 'react';

export default function CloseButton() {
  const navigate = useNavigator();
  return (
    <Button
      variant={'link'}
      onClick={() => {
        navigate({ to: IN_HOUSE_URL.내강의실 });
      }}
    >
      <NavigationClose size={24} color={neutralDay.gray100} />
    </Button>
  );
}
