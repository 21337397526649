'use client';

export default function BackButton() {
  if (window?.history.length <= 1) return null;
  return (
    <button
      onClick={() => {
        window.history.back();
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="icon">
          <path
            id="Vector 150"
            d="M12.5 16.667L5.83333 10.0003L12.5 3.33366"
            stroke="#141617"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </button>
  );
}
