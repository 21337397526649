import { type BreakpointContext, BreakpointProvider } from './BreakpointProvider';
import { ThemeProvider, type ThemeProviderProps } from './ThemeProvider';
import { type PropsWithChildren } from 'react';

export interface DesignSystemProviderProps {
  breakpoints?: BreakpointContext;
  theme?: ThemeProviderProps;
}

export function DesignSystemProvider({
  children,
  breakpoints,
  theme,
}: PropsWithChildren<DesignSystemProviderProps>) {
  return (
    <BreakpointProvider narrow={0} wide={1024} {...breakpoints}>
      <ThemeProvider {...theme}>{children}</ThemeProvider>
    </BreakpointProvider>
  );
}
