import {
  useGetUserCouponCount,
  useGetUserMyPageInfo,
} from '@/(_pages)/(withGnb)/payment/_domain/_data/_queries/user.query';
import { useNavigator } from '@/_global/_business/_hooks/useNavigator';
import { IN_HOUSE_URL } from '@/_global/_constants/urls';
import { useOnOutsideClick } from '@/(_pages)/(withGnb)/_domain/_business/_hook/useOnOutsideClick';
import { Divider, Flex } from '@/_global/_styles/design-system/components';
import { Text } from '@/_global/_styles/design-system/components/Text/Text';
import { neutralDay, scc } from '@/_global/_styles/design-system/foundation/color';
import Image from 'next/image';
import { Dispatch, SetStateAction, useRef } from 'react';
import { CouponIcon, PointIcon } from './MyPage.svg';
import * as S from './myPageDashboard.css';
import { getUserId } from '@/_global/_business/_service/cookie.client.service';

const imgLink = `https://spartacodingclub.kr/_next/image?url=https%3A%2F%2Fstatic.spartacodingclub.kr%2FTeamSparta-Inc%2Fscc-frontend%2Fassets%2Fimages%2Fprofile%2Fdeveloper.png&w=2048&q=100`;

export default function MyPageDashboard({
  show,
  setShow,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) {
  const myPageRef = useRef(null);
  useOnOutsideClick(myPageRef, () => setShow(false));
  const navigate = useNavigator();
  const { data: userInfo } = useGetUserMyPageInfo();
  const { data: couponCount } = useGetUserCouponCount();

  const userId = getUserId();

  return (
    show && (
      <section className={S.Wrapper} ref={myPageRef}>
        {/* Top */}
        <div className={S.TopWrapper}>
          {/* 프로필 */}
          <Flex direction="row" align="center" justify="start" gap={16}>
            <div className={S.ProfileWrapper}>
              <div className={S.ProfileWrapper}>
                <Image src={imgLink} alt="profile" unoptimized fill />
              </div>
            </div>
            <Flex direction="column" align="start" justify="center" gap={2}>
              <Text as="p" font="mTitle1" color={neutralDay.gray100}>
                {userInfo?.name}
              </Text>
              <Text as="p" font="mCaption2" color={neutralDay.gray70}>
                {userInfo?.email}
              </Text>
            </Flex>
          </Flex>
          {/* 포인트 & 쿠폰 */}
          <div className={S.PointAndCouponWrapper}>
            <button
              className={S.ButtonWrapper}
              onClick={() => {
                window.location.href = `${IN_HOUSE_URL.포인트}`;
              }}
            >
              <Flex direction="column" align="center" justify="center" gap={2}>
                <Flex direction="row" align="center" justify="center" gap={4}>
                  <PointIcon />
                  <Text as="p" font="mBody3" color={neutralDay.gray90}>
                    포인트
                  </Text>
                </Flex>
                <Text as="p" font="mBody3" color={scc.red100}>
                  {userInfo?.point.toLocaleString()}원
                </Text>
              </Flex>
            </button>
            <div className={S.VerticalDivider} />
            <button
              className={S.ButtonWrapper}
              onClick={() => {
                window.location.href = `${IN_HOUSE_URL.쿠폰}`;
              }}
              aria-label={'나의 쿠폰'}
            >
              <Flex direction="column" align="center" justify="center" gap={2}>
                <Flex direction="row" align="center" justify="center" gap={4}>
                  <CouponIcon />
                  <Text as="p" font="mBody3" color={neutralDay.gray90}>
                    쿠폰
                  </Text>
                </Flex>
                <Text as="p" font="mBody3" color={scc.red100}>
                  {couponCount ? `${couponCount}장` : '없음'}
                </Text>
              </Flex>
            </button>
          </div>
        </div>
        <Divider color={neutralDay.gray10} isFlexItem orientation="horizontal" size={1} />
        {/* Bottom Menu */}
        <ul>
          {getMenuItems({ userId }).map((menu) => (
            <MenuItem
              key={menu.label}
              label={menu.label}
              onClick={() => {
                navigate({ to: menu.link });
              }}
            />
          ))}
        </ul>
        <Divider color={neutralDay.gray10} isFlexItem orientation="horizontal" size={1} />
        <button
          className={S.LoggedOutWrapper}
          type="button"
          onClick={() => {
            navigate({ to: IN_HOUSE_URL.로그아웃 });
          }}
        >
          <Text as="p" font="mBody4" color={neutralDay.gray80}>
            로그아웃
          </Text>
        </button>
      </section>
    )
  );
}

const MenuItem = ({ label, onClick: handleClick }: { label: string; onClick: () => void }) => {
  return (
    <button className={S.MenuItemWrapper} onClick={handleClick}>
      <Text as="p" font="mBody1" color={neutralDay.gray100}>
        {label}
      </Text>
    </button>
  );
};

const getMenuItems = ({ userId }: { userId: string }) => [
  {
    label: '내 강의실',
    link: IN_HOUSE_URL.내강의실,
  },
  {
    label: '국비 신청 내역',
    link: IN_HOUSE_URL.국비신청내역,
  },
  {
    label: '수강권',
    link: IN_HOUSE_URL.수강권,
  },
  {
    label: '수강증/수료증',
    link: IN_HOUSE_URL.수강증,
  },
  {
    label: '숙제 피드백',
    link: `${IN_HOUSE_URL.숙제피드백}/${userId}`,
  },
  {
    label: '계정',
    link: IN_HOUSE_URL.계정,
  },
];
