import {
  getFooterConfig,
  getGnbConfig,
} from '@/(_pages)/(withGnb)/_domain/_business/_service/gnb.service';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export const useGnbConfig = (initialPath?: string) => {
  const current = usePathname();

  return useMemo(() => getGnbConfig(current ?? initialPath), [current]);
};
  
export const useFooterConfig = (initialPath: string) => {
  const current = usePathname();
  return useMemo(() => getFooterConfig(current ?? initialPath), [current]);
};
