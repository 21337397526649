import {
  convertEnrollmentCourseDtoIntoProductDto,
  convertEnrollmentDtoIntoStructure,
} from '@/(_pages)/(withGnb)/classroom/_domain/_business/_service/convertDto.service';
import { GetAllEnrollmentsResV1 } from '@/(_pages)/(withGnb)/classroom/_domain/_types/classroom.type';
import { GetAllEnrollmentsRes } from '@/_global/_data/_models';
import { SystemVersion } from '@/_global/_types/global.type';
import Axios from '@/_global/_utils/axiosInstance.util';
import { queryOptions, useQueries } from '@tanstack/react-query';

export const classroomKeys = {
  all: ['classroom'],
  enrollments: (version: SystemVersion) => [...classroomKeys.all, 'enrollments', version],
};

export const classroomQuery = {
  // 구 시스템
  getCourseEnrollments: () =>
    queryOptions({
      queryKey: classroomKeys.enrollments(1),
      queryFn: () =>
        Axios<GetAllEnrollmentsResV1>(
          'get',
          `${process.env.NEXT_PUBLIC_ONLINE_API}/v2/enrolleds/v3/classroom`,
        ),
      select: (data) => convertEnrollmentCourseDtoIntoProductDto(data),
      throwOnError: false,
    }),
  // 신 시스템
  getProductEnrollments: () =>
    queryOptions({
      queryKey: classroomKeys.enrollments(2),
      queryFn: () => Axios<GetAllEnrollmentsRes>('get', `/enrollment-record/enrollment/all`),
      select: (data) => data.result,
      throwOnError: false,
    }),
};

export const useGetClassroomEnrollments = () =>
  useQueries({
    queries: [classroomQuery.getCourseEnrollments(), classroomQuery.getProductEnrollments()],
    combine: (results) => {
      const isError = results.some((result) => result.isError);
      const error = results.find((result) => result.isError)?.error;

      return {
        data: convertEnrollmentDtoIntoStructure(results),
        isLoading: results.some((result) => result.isLoading),
        isError,
        error,
      };
    },
  });
