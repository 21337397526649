export const applyEnvUrl = (url: string) => {
  const sccUrl = process.env.NEXT_PUBLIC_SCC_URL || 'https://spartacodingclub.kr';
  const onlineUrl = process.env.NEXT_PUBLIC_ONLINE_URL || 'https://online.spartacodingclub.kr';
  const academiaUrl = process.env.NEXT_PUBLIC_BASE_URL || 'https://academia.spartacodingclub.kr';

  return url
    .replace('http://127.0.0.1:3333', sccUrl)
    .replace('https://testmain.spartacodingclub.kr', sccUrl)
    .replace('https://spartacodingclub.kr', sccUrl)
    .replace('http://127.0.0.1:3000', onlineUrl)
    .replace('https://testonline.spartacodingclub.kr', onlineUrl)
    .replace('https://online.spartacodingclub.kr', onlineUrl)
    .replace('http://127.0.0.1:1111', academiaUrl)
    .replace('https://testacademia.spartacodingclub.kr', academiaUrl)
    .replace('https://academia.spartacodingclub.kr', academiaUrl);
};
