import { useRouter } from 'next/navigation';

export const useNavigator = () => {
  const router = useRouter();
  return ({ to }: { to: string }) => {
    // TODO 로그
    if (to.includes('://')) {
      window.location.href = to;
    } else {
      router.push(to);
    }
  };
};
