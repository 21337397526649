'use client';

import { useNavigator } from '@/_global/_business/_hooks/useNavigator';
import { IN_HOUSE_URL } from '@/_global/_constants/urls';
import * as S from './spartaIcon.css';

export default function SpartaLogo({ isMainLogo = true }: { isMainLogo?: boolean }) {
  const navigate = useNavigator();
  return (
    <button
      className={S.SpartaLogoContainer}
      onClick={() => {
        navigate({ to: IN_HOUSE_URL.메인 });
      }}
    >
      {isMainLogo ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 25" fill="none">
          <path
            d="M38.303 21.4932V21.9293H38.7118V22.3646H39.1205V22.8007H39.5292V23.2368H39.1205V23.6733H38.7118V24.1094H38.303V24.5455H37.4856V24.1103H37.8943V23.6741H38.303V23.2376H38.7118V22.8015H38.303V22.3646H37.8943V21.9293H37.4856V21.4932H38.303Z"
            fill="#E8344E"
          />
          <path
            d="M36.9944 21.5312H36.2824V21.9519H35.8802V22.3805H35.4988V22.8015H35.0967V23.2297H34.7157V23.6507H34.3135V24.0793H33.9326V24.5074H34.6446V24.0793H35.0255V23.6582H35.4281V23.2297H35.8087V22.809H36.2113V22.3805H36.5918V21.9594H36.9944V21.5312Z"
            fill="#E8344E"
          />
          <path
            d="M29.5506 24.5464V24.1103H29.1419V23.6741H28.7332V23.2376H28.3245V22.8015H28.7332V22.3646H29.1419V21.9293H29.5506V21.4932H30.3681V21.9293H29.9594V22.3646H29.5506V22.8007H29.1419V23.2368H29.5506V23.6733H29.9594V24.1094H30.3681V24.5455L29.5506 24.5464Z"
            fill="#E8344E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.7646 7.72319L39.9004 0.307617L46.1538 7.64786L43.3892 16.8828L38.4727 17.8643L32.2254 24.6851L30.8529 19.3852L6.25387 24.295L0 16.9548L2.7646 7.72319ZM17.5002 14.8955V10.6101H18.0757V9.75296H18.9128V8.8958H21.4239V9.75296H22.2606V10.6101H22.8365V14.8955H21.4239V13.1795H18.9128V14.8955H17.5002ZM18.9128 12.324H21.4239V10.608H20.5869V9.75296H19.7498V10.6101H18.9128V12.324ZM5.00349 14.8955V14.0383H4.22692V13.1812H5.86631L5.90718 14.0383H8.06524V12.3223H5.00349V11.4669H4.26779V9.75088H5.00349V8.89581H9.01429V9.75297H9.74999V10.6101H8.11101V9.75297H5.90881V11.469H9.01511V12.324H9.75081V14.04H9.01511V14.8972L5.00349 14.8955ZM36.8206 10.6101V14.8955H38.2332V13.1795H40.7444V14.8955H42.1569V10.6101H41.5815V9.75296H40.7444V8.8958H38.2336V9.75296H37.3969V10.6101H36.8206ZM40.7444 12.324H38.2332V10.6101H39.0727V9.75296H39.9073V10.608H40.7444V12.324ZM32.5807 14.8955V9.75296H30.8179V8.8958H35.8403V9.75296H34.1236V14.8955H32.5807ZM15.777 8.8958V9.75296H16.2855V11.4535H15.777V12.3106H12.5538V14.8955H11.2295V9.76049H11.8259V8.8958H15.777ZM12.5538 11.4535H14.9841L14.982 9.75296H12.5538V11.4535ZM29.0936 14.0383V13.1812H28.2566V12.1901H29.0442V11.4472H29.6691V9.75296H29.0936V8.8958H25.0636V9.76049H24.4616V14.8955H25.8312V12.3244H26.7954V13.2824H27.5054V14.0316H28.2631L28.2578 14.8955H29.9315V14.0383H29.0936ZM25.8312 11.4677V9.7517H28.2578V10.6093L28.2631 11.4664L25.8312 11.4677Z"
            fill="#E8344E"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="86"
          height="26"
          viewBox="0 0 86 26"
          fill="none"
        >
          <g clipPath="url(#clip0_8837_396)">
            <path
              d="M40.6128 22.5381V23.0005H41.0462V23.462H41.4795V23.9244H41.9129V24.3868H41.4795V24.8496H41.0462V25.312H40.6128V25.7744H39.7461V25.3129H40.1794V24.8505H40.6128V24.3877H41.0462V23.9253H40.6128V23.462H40.1794V23.0005H39.7461V22.5381H40.6128Z"
              fill="#E8344E"
            />
            <path
              d="M39.2248 22.5791H38.4699V23.0251H38.0435V23.4795H37.6391V23.9259H37.2127V24.3799H36.8088V24.8263H36.3824V25.2807H35.9785V25.7347H36.7334V25.2807H37.1373V24.8343H37.5642V24.3799H37.9676V23.9339H38.3945V23.4795H38.7979V23.0331H39.2248V22.5791Z"
              fill="#E8344E"
            />
            <path
              d="M31.3333 25.7753V25.3129H30.8999V24.8505H30.4666V24.3877H30.0332V23.9253H30.4666V23.462H30.8999V23.0005H31.3333V22.5381H32.2V23.0005H31.7666V23.462H31.3333V23.9244H30.8999V24.3868H31.3333V24.8496H31.7666V25.312H32.2V25.7744L31.3333 25.7753Z"
              fill="#E8344E"
            />
            <path
              d="M42.3061 0.0761719L2.9322 7.93868L0.000976562 17.7267L6.63177 25.5093L32.7133 20.3036L34.1686 25.9229L40.7924 18.6909L46.0053 17.6503L48.9365 7.8588L42.3061 0.0761719Z"
              fill="#E8344E"
            />
            <path
              d="M5.30482 15.5429V14.6341H4.48145V13.7253H6.21964L6.26297 14.6341H8.5511V12.8147H5.30482V11.9076H4.52478V10.0882H5.30482V9.18164H9.55735V10.0905H10.3374V10.9993H8.59963V10.0905H6.26471V11.9099H9.55822V12.8165H10.3383V14.6359H9.55822V15.5447L5.30482 15.5429Z"
              fill="white"
            />
            <path
              d="M18.5547 15.5429V10.9993H19.1649V10.0905H20.0524V9.18164H22.7149V10.0905H23.602V10.9993H24.2126V15.5429H22.7149V13.7235H20.0524V15.5429H18.5547ZM20.0524 12.8165H22.7149V10.9971H21.8274V10.0905H20.9399V10.9993H20.0524V12.8165Z"
              fill="white"
            />
            <path
              d="M39.041 15.5429V10.9993H39.652V10.0905H40.5391V9.18164H43.2012V10.0905H44.0888V10.9993H44.6989V15.5429H43.2012V13.7235H40.5387V15.5429H39.041ZM40.5387 12.8165H43.2012V10.9971H42.3137V10.0905H41.4288V10.9993H40.5387V12.8165Z"
              fill="white"
            />
            <path
              d="M34.5439 15.5429V10.0905H32.6748V9.18164H37.9999V10.0905H36.1798V15.5429H34.5439Z"
              fill="white"
            />
            <path
              d="M16.7278 10.0905V9.18164H12.5385V10.0984H11.9062V15.5429H13.3103V12.8023H16.7278V11.8934H17.2669V10.0905H16.7278ZM15.8871 11.8934H13.3103V10.0905H15.8849L15.8871 11.8934Z"
              fill="white"
            />
            <path
              d="M30.8478 14.6341V13.7253H29.9602V12.6745H30.7953V11.8868H31.4579V10.0905H30.8478V9.18164H26.5749V10.0984H25.9365V15.5429H27.3887V12.8169H28.411V13.8327H29.1637V14.627H29.9672L29.9615 15.5429H31.7361V14.6341H30.8478ZM27.3887 11.9085V10.0891H29.9615V10.9984L29.9672 11.9072L27.3887 11.9085Z"
              fill="white"
            />
            <path d="M68.6117 3.35938H67.6865V4.31745H68.6117V3.35938Z" fill="#E8344E" />
            <path d="M69.6615 3.35938H68.7363V4.31745H69.6615V3.35938Z" fill="#E8344E" />
            <path d="M72.8207 3.35938H71.8955V4.31745H72.8207V3.35938Z" fill="#E8344E" />
            <path d="M71.7706 3.35938H70.835V4.31745H71.7706V3.35938Z" fill="#E8344E" />
            <path d="M57.3217 5.52051H56.3965V6.47858H57.3217V5.52051Z" fill="#E8344E" />
            <path d="M54.1622 8.75586H53.2266V9.71393H54.1622V8.75586Z" fill="#E8344E" />
            <path d="M55.2123 8.75586H54.2871V9.71393H55.2123V8.75586Z" fill="#E8344E" />
            <path d="M54.1622 7.68066H53.2266V8.62809H54.1622V7.68066Z" fill="#E8344E" />
            <path d="M54.1622 6.60645H53.2266V7.55387H54.1622V6.60645Z" fill="#E8344E" />
            <path d="M55.2123 9.8418H54.2871V10.7892H55.2123V9.8418Z" fill="#E8344E" />
            <path d="M57.3217 9.8418H56.3965V10.7892H57.3217V9.8418Z" fill="#E8344E" />
            <path d="M55.2123 5.52051H54.2871V6.47858H55.2123V5.52051Z" fill="#E8344E" />
            <path d="M55.2123 7.68066H54.2871V8.62809H55.2123V7.68066Z" fill="#E8344E" />
            <path d="M55.2123 6.60645H54.2871V7.55387H55.2123V6.60645Z" fill="#E8344E" />
            <path d="M56.2725 5.52051H55.3369V6.47858H56.2725V5.52051Z" fill="#E8344E" />
            <path d="M56.2725 9.8418H55.3369V10.7892H56.2725V9.8418Z" fill="#E8344E" />
            <path d="M62.3842 5.52051H61.459V6.47858H62.3842V5.52051Z" fill="#E8344E" />
            <path d="M62.3842 7.68066H61.459V8.62809H62.3842V7.68066Z" fill="#E8344E" />
            <path d="M62.3842 6.60645H61.459V7.55387H62.3842V6.60645Z" fill="#E8344E" />
            <path d="M62.3842 8.75586H61.459V9.71393H62.3842V8.75586Z" fill="#E8344E" />
            <path d="M63.435 8.75586H62.5098V9.71393H63.435V8.75586Z" fill="#E8344E" />
            <path d="M63.435 6.60645H62.5098V7.55387H63.435V6.60645Z" fill="#E8344E" />
            <path d="M63.435 7.68066H62.5098V8.62809H63.435V7.68066Z" fill="#E8344E" />
            <path d="M61.3341 9.8418H60.3984V10.7892H61.3341V9.8418Z" fill="#E8344E" />
            <path d="M60.2758 9.8418H59.3506V10.7892H60.2758V9.8418Z" fill="#E8344E" />
            <path d="M59.2247 6.60645H58.2891V7.55387H59.2247V6.60645Z" fill="#E8344E" />
            <path d="M59.2247 7.68066H58.2891V8.62809H59.2247V7.68066Z" fill="#E8344E" />
            <path d="M62.3842 9.8418H61.459V10.7892H62.3842V9.8418Z" fill="#E8344E" />
            <path d="M59.2247 8.75586H58.2891V9.71393H59.2247V8.75586Z" fill="#E8344E" />
            <path d="M60.2758 8.75586H59.3506V9.71393H60.2758V8.75586Z" fill="#E8344E" />
            <path d="M61.3341 5.52051H60.3984V6.47858H61.3341V5.52051Z" fill="#E8344E" />
            <path d="M60.2758 7.68066H59.3506V8.62809H60.2758V7.68066Z" fill="#E8344E" />
            <path d="M60.2758 6.60645H59.3506V7.55387H60.2758V6.60645Z" fill="#E8344E" />
            <path d="M60.2758 5.52051H59.3506V6.47858H60.2758V5.52051Z" fill="#E8344E" />
            <path d="M69.6615 9.8418H68.7363V10.7892H69.6615V9.8418Z" fill="#E8344E" />
            <path d="M68.6117 7.68066H67.6865V8.62809H68.6117V7.68066Z" fill="#E8344E" />
            <path d="M68.6117 6.60645H67.6865V7.55387H68.6117V6.60645Z" fill="#E8344E" />
            <path d="M68.6117 4.44531H67.6865V5.39274H68.6117V4.44531Z" fill="#E8344E" />
            <path d="M68.6117 5.52051H67.6865V6.47858H68.6117V5.52051Z" fill="#E8344E" />
            <path d="M69.6615 4.44531H68.7363V5.39274H69.6615V4.44531Z" fill="#E8344E" />
            <path d="M69.6615 5.52051H68.7363V6.47858H69.6615V5.52051Z" fill="#E8344E" />
            <path d="M69.6615 7.68066H68.7363V8.62809H69.6615V7.68066Z" fill="#E8344E" />
            <path d="M69.6615 6.60645H68.7363V7.55387H69.6615V6.60645Z" fill="#E8344E" />
            <path d="M69.6615 8.75586H68.7363V9.71393H69.6615V8.75586Z" fill="#E8344E" />
            <path d="M65.4503 8.75586H64.5146V9.71393H65.4503V8.75586Z" fill="#E8344E" />
            <path d="M66.5004 9.8418H65.5752V10.7892H66.5004V9.8418Z" fill="#E8344E" />
            <path d="M66.5004 6.60645H65.5752V7.55387H66.5004V6.60645Z" fill="#E8344E" />
            <path d="M66.5004 7.68066H65.5752V8.62809H66.5004V7.68066Z" fill="#E8344E" />
            <path d="M65.4503 6.60645H64.5146V7.55387H65.4503V6.60645Z" fill="#E8344E" />
            <path d="M66.5004 8.75586H65.5752V9.71393H66.5004V8.75586Z" fill="#E8344E" />
            <path d="M68.6117 9.8418H67.6865V10.7892H68.6117V9.8418Z" fill="#E8344E" />
            <path d="M67.5616 9.8418H66.626V10.7892H67.5616V9.8418Z" fill="#E8344E" />
            <path d="M66.5004 5.52051H65.5752V6.47858H66.5004V5.52051Z" fill="#E8344E" />
            <path d="M67.5616 5.52051H66.626V6.47858H67.5616V5.52051Z" fill="#E8344E" />
            <path d="M65.4503 7.68066H64.5146V8.62809H65.4503V7.68066Z" fill="#E8344E" />
            <path d="M68.6117 8.75586H67.6865V9.71393H68.6117V8.75586Z" fill="#E8344E" />
            <path d="M71.7706 9.8418H70.835V10.7892H71.7706V9.8418Z" fill="#E8344E" />
            <path d="M72.8207 6.60645H71.8955V7.55387H72.8207V6.60645Z" fill="#E8344E" />
            <path d="M72.8207 8.75586H71.8955V9.71393H72.8207V8.75586Z" fill="#E8344E" />
            <path d="M72.8207 5.52051H71.8955V6.47858H72.8207V5.52051Z" fill="#E8344E" />
            <path d="M72.8207 7.68066H71.8955V8.62809H72.8207V7.68066Z" fill="#E8344E" />
            <path d="M71.7706 8.75586H70.835V9.71393H71.7706V8.75586Z" fill="#E8344E" />
            <path d="M71.7706 7.68066H70.835V8.62809H71.7706V7.68066Z" fill="#E8344E" />
            <path d="M71.7706 6.60645H70.835V7.55387H71.7706V6.60645Z" fill="#E8344E" />
            <path d="M72.8207 9.8418H71.8955V10.7892H72.8207V9.8418Z" fill="#E8344E" />
            <path d="M71.7706 5.52051H70.835V6.47858H71.7706V5.52051Z" fill="#E8344E" />
            <path d="M77.9985 6.60645H77.0732V7.55387H77.9985V6.60645Z" fill="#E8344E" />
            <path d="M74.837 9.8418H73.9014V10.7892H74.837V9.8418Z" fill="#E8344E" />
            <path d="M77.9985 5.52051H77.0732V6.47858H77.9985V5.52051Z" fill="#E8344E" />
            <path d="M77.9985 8.75586H77.0732V9.71393H77.9985V8.75586Z" fill="#E8344E" />
            <path d="M77.9985 9.8418H77.0732V10.7892H77.9985V9.8418Z" fill="#E8344E" />
            <path d="M79.0473 9.8418H78.1221V10.7892H79.0473V9.8418Z" fill="#E8344E" />
            <path d="M79.0473 7.68066H78.1221V8.62809H79.0473V7.68066Z" fill="#E8344E" />
            <path d="M76.9473 5.52051H76.0117V6.47858H76.9473V5.52051Z" fill="#E8344E" />
            <path d="M79.0473 6.60645H78.1221V7.55387H79.0473V6.60645Z" fill="#E8344E" />
            <path d="M79.0473 8.75586H78.1221V9.71393H79.0473V8.75586Z" fill="#E8344E" />
            <path d="M77.9985 7.68066H77.0732V8.62809H77.9985V7.68066Z" fill="#E8344E" />
            <path d="M75.8871 9.8418H74.9619V10.7892H75.8871V9.8418Z" fill="#E8344E" />
            <path d="M75.8871 5.52051H74.9619V6.47858H75.8871V5.52051Z" fill="#E8344E" />
            <path d="M74.837 6.60645H73.9014V7.55387H74.837V6.60645Z" fill="#E8344E" />
            <path d="M74.837 7.68066H73.9014V8.62809H74.837V7.68066Z" fill="#E8344E" />
            <path d="M74.837 8.75586H73.9014V9.71393H74.837V8.75586Z" fill="#E8344E" />
            <path d="M74.837 5.52051H73.9014V6.47858H74.837V5.52051Z" fill="#E8344E" />
            <path d="M75.8871 6.60645H74.9619V7.55387H75.8871V6.60645Z" fill="#E8344E" />
            <path d="M75.8871 8.75586H74.9619V9.71393H75.8871V8.75586Z" fill="#E8344E" />
            <path d="M75.8871 7.68066H74.9619V8.62809H75.8871V7.68066Z" fill="#E8344E" />
            <path d="M84.2465 9.8418H83.3213V10.7892H84.2465V9.8418Z" fill="#E8344E" />
            <path d="M83.1944 12.0127H82.2588V12.9708H83.1944V12.0127Z" fill="#E8344E" />
            <path d="M85.2943 5.52051H84.3691V6.47858H85.2943V5.52051Z" fill="#E8344E" />
            <path d="M84.2465 10.917H83.3213V11.8751H84.2465V10.917Z" fill="#E8344E" />
            <path d="M83.1944 9.8418H82.2588V10.7892H83.1944V9.8418Z" fill="#E8344E" />
            <path d="M83.1944 5.52051H82.2588V6.47858H83.1944V5.52051Z" fill="#E8344E" />
            <path d="M84.2465 12.0127H83.3213V12.9708H84.2465V12.0127Z" fill="#E8344E" />
            <path d="M85.2943 6.60645H84.3691V7.55387H85.2943V6.60645Z" fill="#E8344E" />
            <path d="M85.2943 7.68066H84.3691V8.62809H85.2943V7.68066Z" fill="#E8344E" />
            <path d="M84.2465 5.52051H83.3213V6.47858H84.2465V5.52051Z" fill="#E8344E" />
            <path d="M85.2943 10.917H84.3691V11.8751H85.2943V10.917Z" fill="#E8344E" />
            <path d="M85.2943 8.75586H84.3691V9.71393H85.2943V8.75586Z" fill="#E8344E" />
            <path d="M82.1342 5.52051H81.209V6.47858H82.1342V5.52051Z" fill="#E8344E" />
            <path d="M84.2465 6.60645H83.3213V7.55387H84.2465V6.60645Z" fill="#E8344E" />
            <path d="M85.2943 9.8418H84.3691V10.7892H85.2943V9.8418Z" fill="#E8344E" />
            <path d="M84.2465 8.75586H83.3213V9.71393H84.2465V8.75586Z" fill="#E8344E" />
            <path d="M84.2465 7.68066H83.3213V8.62809H84.2465V7.68066Z" fill="#E8344E" />
            <path d="M82.1342 8.75586H81.209V9.71393H82.1342V8.75586Z" fill="#E8344E" />
            <path d="M82.1342 12.0127H81.209V12.9708H82.1342V12.0127Z" fill="#E8344E" />
            <path d="M82.1342 9.8418H81.209V10.7892H82.1342V9.8418Z" fill="#E8344E" />
            <path d="M81.085 6.60645H80.1494V7.55387H81.085V6.60645Z" fill="#E8344E" />
            <path d="M81.085 8.75586H80.1494V9.71393H81.085V8.75586Z" fill="#E8344E" />
            <path d="M82.1342 6.60645H81.209V7.55387H82.1342V6.60645Z" fill="#E8344E" />
            <path d="M82.1342 7.68066H81.209V8.62809H82.1342V7.68066Z" fill="#E8344E" />
            <path d="M81.085 7.68066H80.1494V8.62809H81.085V7.68066Z" fill="#E8344E" />
            <path d="M54.1622 16.8301H53.2266V17.7775H54.1622V16.8301Z" fill="#E8344E" />
            <path d="M57.3217 20.0664H56.3965V21.0138H57.3217V20.0664Z" fill="#E8344E" />
            <path d="M54.1622 17.9053H53.2266V18.8527H54.1622V17.9053Z" fill="#E8344E" />
            <path d="M54.1622 18.9805H53.2266V19.9385H54.1622V18.9805Z" fill="#E8344E" />
            <path d="M57.3217 15.7441H56.3965V16.7022H57.3217V15.7441Z" fill="#E8344E" />
            <path d="M55.2123 16.8301H54.2871V17.7775H55.2123V16.8301Z" fill="#E8344E" />
            <path d="M55.2123 17.9053H54.2871V18.8527H55.2123V17.9053Z" fill="#E8344E" />
            <path d="M56.2725 20.0664H55.3369V21.0138H56.2725V20.0664Z" fill="#E8344E" />
            <path d="M55.2123 20.0664H54.2871V21.0138H55.2123V20.0664Z" fill="#E8344E" />
            <path d="M55.2123 15.7441H54.2871V16.7022H55.2123V15.7441Z" fill="#E8344E" />
            <path d="M55.2123 18.9805H54.2871V19.9385H55.2123V18.9805Z" fill="#E8344E" />
            <path d="M56.2725 15.7441H55.3369V16.7022H56.2725V15.7441Z" fill="#E8344E" />
            <path d="M59.2247 20.0664H58.2891V21.0138H59.2247V20.0664Z" fill="#E8344E" />
            <path d="M59.2247 18.9805H58.2891V19.9385H59.2247V18.9805Z" fill="#E8344E" />
            <path d="M60.2758 16.8301H59.3506V17.7775H60.2758V16.8301Z" fill="#E8344E" />
            <path d="M60.2758 17.9053H59.3506V18.8527H60.2758V17.9053Z" fill="#E8344E" />
            <path d="M60.2758 15.7441H59.3506V16.7022H60.2758V15.7441Z" fill="#E8344E" />
            <path d="M60.2758 14.6689H59.3506V15.6164H60.2758V14.6689Z" fill="#E8344E" />
            <path d="M60.2758 18.9805H59.3506V19.9385H60.2758V18.9805Z" fill="#E8344E" />
            <path d="M60.2758 13.584H59.3506V14.5421H60.2758V13.584Z" fill="#E8344E" />
            <path d="M59.2247 14.6689H58.2891V15.6164H59.2247V14.6689Z" fill="#E8344E" />
            <path d="M60.2758 20.0664H59.3506V21.0138H60.2758V20.0664Z" fill="#E8344E" />
            <path d="M59.2247 16.8301H58.2891V17.7775H59.2247V16.8301Z" fill="#E8344E" />
            <path d="M59.2247 15.7441H58.2891V16.7022H59.2247V15.7441Z" fill="#E8344E" />
            <path d="M59.2247 13.584H58.2891V14.5421H59.2247V13.584Z" fill="#E8344E" />
            <path d="M59.2247 17.9053H58.2891V18.8527H59.2247V17.9053Z" fill="#E8344E" />
            <path d="M66.5424 15.7441H65.6172V16.7022H66.5424V15.7441Z" fill="#E8344E" />
            <path d="M65.4926 15.7441H64.5674V16.7022H65.4926V15.7441Z" fill="#E8344E" />
            <path d="M65.4926 17.9053H64.5674V18.8527H65.4926V17.9053Z" fill="#E8344E" />
            <path d="M65.4926 20.0664H64.5674V21.0138H65.4926V20.0664Z" fill="#E8344E" />
            <path d="M65.4926 18.9805H64.5674V19.9385H65.4926V18.9805Z" fill="#E8344E" />
            <path d="M65.4926 16.8301H64.5674V17.7775H65.4926V16.8301Z" fill="#E8344E" />
            <path d="M66.5424 16.8301H65.6172V17.7775H66.5424V16.8301Z" fill="#E8344E" />
            <path d="M66.5424 20.0664H65.6172V21.0138H66.5424V20.0664Z" fill="#E8344E" />
            <path d="M66.5424 17.9053H65.6172V18.8527H66.5424V17.9053Z" fill="#E8344E" />
            <path d="M66.5424 18.9805H65.6172V19.9385H66.5424V18.9805Z" fill="#E8344E" />
            <path d="M63.3823 15.7441H62.457V16.7022H63.3823V15.7441Z" fill="#E8344E" />
            <path d="M62.3321 16.8301H61.3965V17.7775H62.3321V16.8301Z" fill="#E8344E" />
            <path d="M62.3321 15.7441H61.3965V16.7022H62.3321V15.7441Z" fill="#E8344E" />
            <path d="M62.3321 17.9053H61.3965V18.8527H62.3321V17.9053Z" fill="#E8344E" />
            <path d="M63.3823 20.0664H62.457V21.0138H63.3823V20.0664Z" fill="#E8344E" />
            <path d="M64.4425 20.0664H63.5068V21.0138H64.4425V20.0664Z" fill="#E8344E" />
            <path d="M62.3321 18.9805H61.3965V19.9385H62.3321V18.9805Z" fill="#E8344E" />
            <path d="M63.3823 16.8301H62.457V17.7775H63.3823V16.8301Z" fill="#E8344E" />
            <path d="M63.3823 18.9805H62.457V19.9385H63.3823V18.9805Z" fill="#E8344E" />
            <path d="M63.3823 17.9053H62.457V18.8527H63.3823V17.9053Z" fill="#E8344E" />
            <path d="M71.9682 20.0664H71.043V21.0138H71.9682V20.0664Z" fill="#E8344E" />
            <path d="M71.9682 18.9805H71.043V19.9385H71.9682V18.9805Z" fill="#E8344E" />
            <path d="M69.8588 13.584H68.9336V14.5421H69.8588V13.584Z" fill="#E8344E" />
            <path d="M70.9181 15.7441H69.9824V16.7022H70.9181V15.7441Z" fill="#E8344E" />
            <path d="M70.9181 20.0664H69.9824V21.0138H70.9181V20.0664Z" fill="#E8344E" />
            <path d="M71.9682 15.7441H71.043V16.7022H71.9682V15.7441Z" fill="#E8344E" />
            <path d="M73.0199 16.8301H72.0947V17.7775H73.0199V16.8301Z" fill="#E8344E" />
            <path d="M73.0199 17.9053H72.0947V18.8527H73.0199V17.9053Z" fill="#E8344E" />
            <path d="M71.9682 17.9053H71.043V18.8527H71.9682V17.9053Z" fill="#E8344E" />
            <path d="M71.9682 16.8301H71.043V17.7775H71.9682V16.8301Z" fill="#E8344E" />
            <path d="M73.0199 18.9805H72.0947V19.9385H73.0199V18.9805Z" fill="#E8344E" />
            <path d="M69.8588 14.6689H68.9336V15.6164H69.8588V14.6689Z" fill="#E8344E" />
            <path d="M68.8087 17.9053H67.873V18.8527H68.8087V17.9053Z" fill="#E8344E" />
            <path d="M69.8588 15.7441H68.9336V16.7022H69.8588V15.7441Z" fill="#E8344E" />
            <path d="M68.8087 15.7441H67.873V16.7022H68.8087V15.7441Z" fill="#E8344E" />
            <path d="M68.8087 18.9805H67.873V19.9385H68.8087V18.9805Z" fill="#E8344E" />
            <path d="M68.8087 20.0664H67.873V21.0138H68.8087V20.0664Z" fill="#E8344E" />
            <path d="M68.8087 14.6689H67.873V15.6164H68.8087V14.6689Z" fill="#E8344E" />
            <path d="M68.8087 16.8301H67.873V17.7775H68.8087V16.8301Z" fill="#E8344E" />
            <path d="M69.8588 16.8301H68.9336V17.7775H69.8588V16.8301Z" fill="#E8344E" />
            <path d="M69.8588 17.9053H68.9336V18.8527H69.8588V17.9053Z" fill="#E8344E" />
            <path d="M69.8588 18.9805H68.9336V19.9385H69.8588V18.9805Z" fill="#E8344E" />
            <path d="M69.8588 20.0664H68.9336V21.0138H69.8588V20.0664Z" fill="#E8344E" />
            <path d="M68.8087 13.584H67.873V14.5421H68.8087V13.584Z" fill="#E8344E" />
          </g>
          <defs>
            <clipPath id="clip0_8837_396">
              <rect
                width="85.2941"
                height="25.8467"
                fill="white"
                transform="translate(0 0.0771484)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </button>
  );
}
