import { Responsive } from '@/_global/_styles/design-system/types/responsive';
import { isObject } from '@/_global/_styles/design-system/utils/typeChecks';
import { Breakpoint } from '@/_global/_styles/design-system/types/breakpoint';

export const adapter = <T>(value: Responsive<T>): Record<Breakpoint, T> => {
  const extractValue = (targetPoint: Breakpoint, value: Responsive<T>): T => {
    if (isObject(value)) {
      const keys = Object.keys(value);
      if (keys.includes(targetPoint)) {
        return value[targetPoint]!;
      } else {
        if (keys.includes('narrow')) {
          return value.narrow!;
        }
      }
    }
    return value as T;
  };

  return {
    narrow: extractValue('narrow', value),
    wide: extractValue('wide', value),
    mobile: extractValue('mobile', value),
    tablet: extractValue('tablet', value),
    desktop: extractValue('desktop', value),
  };
};

export const adapterForFlex = <T, R = T>(value: Responsive<T>, mapper?: (value: T) => R) => {
  if (isObject(value)) {
    return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, mapper ? mapper(v) : v]));
  }
  return mapper ? mapper(value as T) : value;
};

export const convertGapValueToMeasurement = (gap: Responsive<number | string>) => {
  return Object.fromEntries(
    new Map(Object.entries(gap).map(([key, value]) => [key, getProperMeasurementType(value)])),
  );
};

export const getProperMeasurementType = (value: number | string): string =>
  typeof value === 'number' ? `${value}px` : value;
