'use client';
import React, { ComponentProps, ElementRef, useContext } from 'react';
import type {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@/_global/_styles/design-system/types/polymorphic';
import {
  FlexAlignItems,
  FlexInterface,
  FlexJustifyContent,
} from '@/_global/_styles/design-system/components/Flex/type';
import { layoutProperties } from '@/_global/_styles/sprinkles/deviceSprinkles.css';
import {
  adapterForFlex,
  convertGapValueToMeasurement,
  getProperMeasurementType,
} from '@/_global/_styles/design-system/utils/responsive';
import { SprinklesContext } from '../Providers/SprinklesContext';

const justifyContentMap: Record<
  FlexJustifyContent,
  keyof typeof layoutProperties.styles.justifyContent.values
> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
};

const alignItemsMap: Record<
  FlexAlignItems,
  keyof typeof layoutProperties.styles.alignItems.values
> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
  stretch: 'stretch',
};

export type FlexProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<
  C,
  FlexInterface
>;

type FlexComponent = <C extends React.ElementType = 'div'>(
  props: FlexProps<C>,
) => React.ReactElement | null;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const _Flex: FlexComponent = React.forwardRef(function FlexBase<
  C extends React.ElementType = 'div',
>(
  {
    direction = 'row',
    align = 'start',
    justify = 'start',
    gap = 0,
    wrap = 'nowrap',
    fullHeight = false,
    fullWidth = false,
    ...restProps
  }: FlexProps<C>,
  ref?: PolymorphicRef<C>,
) {
  const sprinkles = useContext(SprinklesContext);
  const gapValue =
    typeof gap === 'object'
      ? { gap: convertGapValueToMeasurement(gap) }
      : { gap: getProperMeasurementType(gap) };

  const layoutClass = sprinkles({
    display: 'flex',
    ...(direction !== 'row' && { flexDirection: adapterForFlex(direction) }),
    ...(justify !== 'start' && {
      justifyContent: adapterForFlex(justify, (value) => justifyContentMap[value]),
    }),
    alignItems: adapterForFlex(align, (value) => alignItemsMap[value]),
    ...(wrap !== 'nowrap' && { flexWrap: adapterForFlex(wrap) }),
    ...(fullWidth && { width: adapterForFlex(fullWidth, (value) => (value ? '100%' : 'auto')) }),
    ...(fullHeight && { height: adapterForFlex(fullHeight, (value) => (value ? '100%' : 'auto')) }),
    ...(gap !== 0 && gapValue),
  });

  return <div ref={ref} className={layoutClass} {...restProps} />;
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const Column: FlexComponent = React.forwardRef<
  ElementRef<typeof Flex>,
  ComponentProps<typeof Flex>
>(function ColumnBase(
  {
    direction = 'column',
    align = 'start',
    justify = 'start',
    gap = 0,
    wrap = 'nowrap',
    ...restProps
  },
  ref: PolymorphicRef<typeof Flex>,
) {
  return (
    <Flex
      ref={ref}
      direction={direction}
      align={align}
      justify={justify}
      gap={gap}
      wrap={wrap}
      {...restProps}
    />
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const Row: FlexComponent = React.forwardRef<ElementRef<typeof Flex>, ComponentProps<typeof Flex>>(
  function RowBase(
    {
      direction = 'row',
      align = 'start',
      justify = 'start',
      gap = 0,
      wrap = 'nowrap',
      ...restProps
    },
    ref: PolymorphicRef<typeof Flex>,
  ) {
    return (
      <Flex
        ref={ref}
        direction={direction}
        align={align}
        justify={justify}
        gap={gap}
        wrap={wrap}
        {...restProps}
      />
    );
  },
);

export const Flex = Object.assign(_Flex, {
  Column,
  Row,
});
